import { useState, useEffect } from "react";
import TemplatePage from "./App";
import Tabs from "./Custom/Tabs";
import { Card, CardBody } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Cookies from 'js-cookie';

// Cookieに保存するためのユーティリティ関数
const setCookie = (name: string, value: string, days: number) => {
    Cookies.set(name, value, { expires: days });
}

const Template = () => {
    // サイズ等の設定用フック
    const [viewSize, setViewSize] = useState('200');
    const [inputViewSize, setInputViewSize] = useState('180');
    const [previewShow, setPreviewShow] = useState(true);

    // offcanbas用のフック
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        const storedViewSize = Cookies.get('viewSize');
        const storedInputViewSize = Cookies.get('InputViewSize');
        const storedPreviewShowState = Cookies.get('previewShowState');

        if (storedViewSize) {
            let size = decodeURIComponent(storedViewSize);
            setViewSize(size);
        } else {
            let baseSize = "200";
            setViewSize(baseSize);
        }
        if (storedInputViewSize) {
            let inputSize = decodeURIComponent(storedInputViewSize);
            setInputViewSize(inputSize);
        } else {
            let baseSize = "180";
            setInputViewSize(baseSize);
        }
        if (storedPreviewShowState !== undefined) {
            setPreviewShow(decodeURIComponent(storedPreviewShowState) === 'true');
        } else {
            setPreviewShow(true);
        }
    }, []);

    return (
        <TemplatePage>
            <Card>
                <CardBody>
                    <h2>利用規約</h2>
                    <p style={{ fontSize: "14pt" }} >この利用規約（以下、「本規約」といいます。）は、<b>TxH-G</b>（以下、「本サイト」といいます。）の利用条件を定めるものです。本サイトをご利用いただく前に、本規約をよくお読みください。本サイトを利用することにより、本規約に同意したものとみなされます。</p>

                    <div>
                        <h4>第1条（適用範囲）</h4>
                        <p style={{ fontSize: "14pt" }} >本規約は、本サイトを利用するすべてのユーザーに適用されます。</p>
                        <p />
                        <br />

                        <h4>第2条（利用目的）</h4>
                        <p style={{ fontSize: "14pt" }} >本サイトは、テキスト変換機能を提供し、ユーザーが自身の作業を効率化することを目的としています。</p>
                        <p />
                        <br />

                        <h4>第3条（小説投稿サイトとの関係）</h4>
                        <p style={{ fontSize: "14pt" }} >本サイトは、個人運営・法人運営を問わず、あらゆる小説投稿サイト(以下、「小説投稿サイト」といいます。)とは営利的な関係はなく、個人による非公式のサポートサイトです。ユーザーは、本サイトが小説投稿サイトと直接の関係がないことを理解し、承諾した上で利用してください。</p>
                        <p />
                        <br />

                        <h4>第4条（禁止事項）</h4>
                        <p style={{ fontSize: "14pt" }} >ユーザーは、本サイトの利用に際して、以下の行為を行ってはなりません。</p>

                        <li>法律または公序良俗に反する行為</li>
                        <li>他のユーザーまたは第三者の権利を侵害する行為</li>
                        <li>あらゆる小説投稿サイトの規約に違反する目的で使用する行為</li>
                        <li>本サイトの運営を妨害する行為</li>
                        <li>無断での商業利用</li>
                        <p />
                        <br />

                        <h4>第5条（免責事項）</h4>
                        <p style={{ fontSize: "14pt" }} >本サイトは、提供するサービスの内容および利用に関して、一切の保証を行いません。本サイトの利用により発生した損害について、運営者は一切の責任を負いません。また、本サイトは予告なく削除される可能性があります。</p>
                        <p />
                        <br />

                        <h4>第6条（利用停止）</h4>
                        <p style={{ fontSize: "14pt" }} >運営者は、ユーザーが本規約に違反したと判断した場合、事前の通知なく、ユーザーの本サイト利用を停止することができます。</p>
                        <p />
                        <br />

                        <h4>第7条（規約の変更）</h4>
                        <p style={{ fontSize: "14pt" }} >運営者は、本規約を予告なく変更することができ、変更後の規約は本サイトに掲載された時点で効力を生じます。</p>
                        <p />
                        <br />

                        <h4>第8条（準拠法）</h4>
                        <p style={{ fontSize: "14pt" }} >本規約は、日本法に準拠し、ユーザーと運営者との間で発生した紛争については、日本の法律に従って解決されます。</p>
                        <p />
                        <br />

                        <h4>お問い合わせ</h4>
                        <p style={{ fontSize: "14pt" }} >本サイトに関するお問い合わせは、以下の連絡先までお願いいたします。</p>

                        <li>e-mail: txh-g@txh-g.tank.jp</li>
                        <li>SNS(X): @Siotasio</li>
                        <li>wavebox: <a href="https://wavebox.me/wave/a43bd4zea9p78j8j/">Siotasio - wavebox</a> </li>
                        <p />
                        <br />
                    </div>
                </CardBody>
            </Card>
        </TemplatePage>
    );
}

export default Template;