import React from 'react';
import Form from 'react-bootstrap/Form';

interface CustomRangeProps {
    onRangeChange: (value: number) => void;
    size?: number;
}

const CustomRange: React.FC<CustomRangeProps> = ({ onRangeChange, size = 20 }) => {
    const handleRangeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        onRangeChange(Number(e.target.value)); // 値を数値に変換
    };

    return (
        <>
            <Form.Range className="mb-3" value={size} onChange={handleRangeChange} />
        </>
    );
};


export default CustomRange;