import TemplatePage from "./App";
import { Accordion, Card, CardBody, Badge } from 'react-bootstrap';
import CustomButton from "./Custom/CustomButton";
import Tabs from "./Custom/Tabs";
import { Link } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';

const BlogApp = () => {
    const TABS = [
        {
            id: 'log',
            title: '履歴',
            content: (
                <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>最新情報</Accordion.Header>
                    <Accordion.Body>
                    <h2>10月</h2>
                    <dl>
                        <dt><h4>10/28 実装/お知らせ <Badge bg="danger">New</Badge></h4></dt>
                        <dd><b style={{ color: "#dc3545" }}>NEWS</b> - 微調整と機能アップデートを完了しました</dd>
                        <dd><b style={{ color: "#0d6efd" }}>Edit/RES</b> - 「MODIFY」タブを廃止。「VIEW」タブの名称を「EDIT」に変更し、EDIT内にレス編集/削除ボタンを追加。※このボタンはCODEコピー時にはデザインに反映されません</dd>
                        <dd><b style={{ color: "green"}}>ALL</b> - <u>今後のメンテナンスについて。基本隔週で日曜日夜間に定期実施します。次回のメンテナンスは11月10日～11日の間に実施されます。</u></dd>
                        <hr />
                        <dt><h4>10/24 実装/お知らせ</h4></dt>
                        <dd><b style={{ color: "#dc3545" }}>NEWS</b> - 微調整と機能アップデートを完了しました</dd>
                        <dd><s><b style={{ color: "#0d6efd" }}>Edit</b> - 「RES」にて「MODIFY」タブを追加。投稿したレスを編集・削除可能になった</s></dd>
                        <dd><b style={{ color: "#0d6efd" }}>Simple/Edit/Setting</b> - ボタン位置やサイズ等の調整</dd>
                        <hr />
                        <dt><h4>10/23 実装/お知らせ</h4></dt>
                        <dd><b style={{ color: "#dc3545" }}>NEWS</b> - 微調整を完了しました</dd>
                        <dd><b style={{ color: "#0d6efd" }}>Edit</b> - 「RES」にてテキストコピー機能を追加、表示文字サイズ調整機能</dd>
                        <dd><b style={{ color: "#0d6efd" }}>Simple/Edit/Setting</b> - ボタン位置やサイズ等の調整、押下後のアラート機能等を修正</dd>
                        <dd><b style={{ color: "#0d6efd" }}>Service</b> - 「利用規約」ページを追加</dd>
                        <hr />
                    </dl>
                    <h2>8月</h2>
                    <dl>
                    <dt><h4>8/9 実装/お知らせ <Badge bg="danger">New</Badge></h4></dt>
                    <dd><b style={{color: "#dc3545"}}>NEWS</b> - メンテナンスは終了しました。</dd>
                    <dd><b style={{color: "#0d6efd"}}>Edit</b> - 「CHAT」を実装。LINE風の特殊タグを生成可能</dd>
                    <hr />
                    <dt><h4>8/7 お知らせ</h4></dt>
                    <dd><b style={{color: "#dc3545"}}>NEWS</b> - 8/9 22:00から24:00までの間、新機能実装のためメンテナンスを実施します
                    → <span style={{color: "#dc3545"}}>8/9 終了しました</span></dd>
                    <dd></dd>
                    <hr />
                    </dl>
                    <h2>7月</h2>
                    <dl>
                    <dt><h4>7/27 実装</h4></dt>
                    <dd><b style={{color: "#0d6efd"}}>All</b> - 23:00前後 メンテナンス終了</dd>
                    <dd><b style={{color: "#0d6efd"}}>All</b> - BLOGページ追加、画面下部にお問い合わせ先等のLINKを追加</dd>
                    <dd><b style={{color: "#0d6efd"}}>Edit</b> - ドロップダウン形式に変更、匿名掲示板以外も選択できるよう修正</dd>
                    <dd><b style={{color: "#0d6efd"}}>Edit</b> - 「CHANGER」を実装。ルビをつけたい単語を複数設定し一括で変更できる</dd>
                    <hr />
                    <dd><b style={{color: "#0d6efd"}}>Simple, Edit</b> - サイドバー(特殊タグをプレビュー)を実装</dd>
                    <dd><b style={{color: "#0d6efd"}}>Simple, Edit, Setting</b> - 固定していたプレビュー画面に固定化のOn/Off切り替えを実装</dd>
                    <dd><b style={{color: "#0d6efd"}}>Setting</b> - プレビュー画面、入力画面のサイズ設定欄、固定化On/Off切り替えの設定を実装</dd>
                    <dd><b style={{color: "#0d6efd"}}></b></dd>
                    <dd><b style={{color: "#0d6efd"}}></b></dd>

                    <hr />
                    <dt><h4>7/22 実装</h4></dt>
                    <dd><b style={{color: "#0d6efd"}}>Simple</b> - マシュマロ風、大百科風、SNS風、競馬掲示板風の簡易タグ</dd>
                    <dd><b style={{color: "#0d6efd"}}>Edit</b> - 匿名掲示板風のジェネレーター</dd>
                    </dl>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>下に行くにつれて過去の履歴になります。</Accordion.Header>
                    <Accordion.Body>
                    {/** */}
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            ),
        },
        {
            id: 'res',
            title: '返信',
            content: (
                <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>最新の返信</Accordion.Header>
                        <Accordion.Body>
                            <p>お問い合わせにはこちらで返信します。</p>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>2024/07/22 ～</Accordion.Header>
                        <Accordion.Body>
                            {/* 2024/07/22以降の情報をここに追加 */}
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            ),
        },
    ];

    return (
        <TemplatePage>
            <Card>
                <CardBody>
                    <h3>ジェネレーターに関する情報まとめ</h3>
                    <span>機能追加や修正情報の詳細に関してはこちらでご報告します。</span>
                    <p>実装済み機能に対するお問い合わせや、追加してほしい機能のご要望等は、<Link target='_blank' to='https://wavebox.me/wave/a43bd4zea9p78j8j/'>こちら</Link>の匿名ツールを介してお送りください。</p>
                    <p>お問い合わせに対する返信は「返信」タブにて、お問い合わせ内容を全文公開した状態に行いますので、ご注意ください。</p>
                </CardBody>
            </Card>
            <p />
            <Tabs tabs={TABS} />
        </TemplatePage>
    );
}

export default BlogApp;