import { useState, useEffect, useRef } from "react";
import TemplatePage from "./App";
import Tabs from "./Custom/Tabs";
import { Form, Card, CardBody, Offcanvas } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Cookies from 'js-cookie';
import { Chat_Union, ChatH_Union } from "./Custom/BBS/Option";
import CustomButton from "./Custom/CustomButton";
import CustomForm from "./Custom/BBS/CustomForm";
import CustomSelect from "./Custom/BBS/CustomSelect";
import CustomTextbox from "./Custom/CustomTextbox";

type Chat_Content = {
    res_check: number; // 1: 自分, 2: 相手 
    res_user: string; // 名前
    res_text: string; // 内容
    res_read: number; // 0: 未読, 1: 既読
}

interface Option {
    value: string;
    label: string;
}

// Cookieに保存するためのユーティリティ関数
const setCookie = (name: string, value: string, days: number) => {
    Cookies.set(name, value, { expires: days });
}

// localStorageに保存するための関数
const saveChatToLocalStorage = (chatContent: Chat_Content[]) => {
    localStorage.setItem('chatContent', JSON.stringify(chatContent));
};

// localStorageから取得するための関数
const getChatFromLocalStorage = (): Chat_Content[] => {
    const chatContentString = localStorage.getItem('chatContent');
    if (chatContentString) {
        return JSON.parse(chatContentString) as Chat_Content[];
    }
    return [];
};

const Template = () => {

    // チャット用のフック
    const [chatContent, SetChatContent] = useState<Chat_Content[]>([]);
    const [userNumber, setUserNumber] = useState(1); // 1: 自分, 2: 相手
    const [userName, setUserName] = useState("自分"); // 名前
    const [userText, setUserText] = useState(""); // 内容
    const [userRead, setUserRead] = useState(1); // 0: 未読, 1: 既読
    const [chatTitle, setChatTitle] = useState(""); // チャット全体のタイトル
    const [viewChatText, setViewChatText] = useState(""); // 表示するテキスト
    const [viewChatCode, setViewChatCode] = useState(""); // 表示するコード

    // 履歴用のフック
    const [userNameBox, setUserNameBox] = useState<Option[]>([{ value: "自分", label: "自分" }]);

    // 特定の value が userNameBox 内に存在するか確認する関数
    const isValueInUserNameBox = (valueToCheck: string): boolean => {
        return userNameBox.some(option => option.value === valueToCheck);
    };

    // サイズ等の設定用フック
    const [viewSize, setViewSize] = useState('200');
    const [inputViewSize, setInputViewSize] = useState('180');
    const [previewShow, setPreviewShow] = useState(true);

    // offcanbas用のフック
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const repTextRef = useRef<HTMLDivElement>(null);
    const hRepTextRef = useRef<HTMLDivElement>(null);

    const setBase = (cTitle: string, cChatContent: Chat_Content[] = []) => {
        let base = cChatContent;
        let viewChat = Chat_Union(cTitle, base);
        let h_ViewChat = ChatH_Union(cTitle, base);
        setViewChatText(viewChat);
        setViewChatCode(h_ViewChat);
        SetChatContent(base);
    }

    const Submit = () => {
        let newContent = [...chatContent, { res_check: userNumber, res_user: userName, res_text: userText, res_read: userRead }]
        setBase(chatTitle, newContent);

        // Cookie処理
        saveChatToLocalStorage(newContent);
        setCookie("chatTitle", chatTitle, 30);
        setCookie("chatNameBox", encodeURIComponent(JSON.stringify(userNameBox)), 30);

        // リセット処理
        setUserText("");
    }

    const onClick = () => {
        let checkName = userName;
        if(!isValueInUserNameBox(checkName)){
            let nameBox = [...userNameBox, { value: userName, label: userName }]
            setUserNameBox(nameBox);
        }
        Submit();
    };

    // ChatTitle
    const handleTitleChange = (value: string) => {
        setChatTitle(value);
        // VIEWのタイトルも更新する
        let viewChat = Chat_Union(value, chatContent);
        setViewChatText(viewChat);
    };

    // Chat user Number
    const handleUserNumberChange = () => {
        if (userNumber === 1) {
            setUserNumber(2);
            setUserName("");
        } else {
            setUserNumber(1);
            setUserName("自分");
        }
    };

    // Chat User Name
    const handleUserNameChange = (value: string) => {
        setUserName(value);
    };

    // Chat User Text
    const handleUserTextChange = (value: string) => {
        setUserText(value);
    };

    // Chat User Read
    const handleUserReadChange = () => {
        if (userRead === 0) {
            setUserRead(1);
        } else {
            setUserRead(0);
        }
    };

    // Chat User Name Select box
    const handleSelectChange = (value: string) => {
        if (value !== "会話相手の履歴 ▼") {
            setUserName(value);
        }
    };

    const copyToClipboard = (text: string) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                alert("コピーしました。");
            })
            .catch(err => {
                alert("Failed to copy text to clipboard: 詳細はコンソールログを確認してください。");
                console.error('Failed to copy text to clipboard:', err);
                // エラーハンドリングを行う場合はここで適切に処理を追加します
            });
    };

    const onClick_Copy = () => {
        copyToClipboard(viewChatCode);
    }

    const onClick_Reset = () => {
        const userConfirmed = window.confirm('リセットすると復元できません。\nCookieも削除されますがよろしいですか？');
        if (userConfirmed) {
            // ユーザーがOKをクリックした場合の処理
            let titles = chatTitle;

            // localStorageのリセット
            localStorage.removeItem('chatContent');
            Cookies.remove('chatTitle');
            Cookies.remove('chatNameBox');

            console.log(`タイトル：${titles} はリセットされました。`);
            
            setChatTitle("");
            setUserName("");
            setUserNameBox([]);
        } else {
            // ユーザーがキャンセルをクリックした場合の処理
            alert(`リセットがキャンセルされました。`);
        }
    };

    useEffect(() => {
        if (repTextRef.current) {
            repTextRef.current.scrollTop = repTextRef.current.scrollHeight;
        }
    }, [viewChatText]);

    useEffect(() => {
        const storedChatContent = getChatFromLocalStorage();
        let title = "";
        let nameBox = [];

        const storedChatTitle = Cookies.get('chatTitle');
        if(storedChatTitle){
            title = decodeURIComponent(storedChatTitle);
            setChatTitle(title);
        }

        const storedChatNameBox = Cookies.get('chatNameBox');
        if(storedChatNameBox){
            nameBox = JSON.parse(decodeURIComponent(storedChatNameBox));
            setUserNameBox(nameBox);
        }

        setBase(title, storedChatContent);

        const storedViewSize = Cookies.get('viewSize');
        const storedInputViewSize = Cookies.get('InputViewSize');
        const storedPreviewShowState = Cookies.get('previewShowState');

        if (storedViewSize) {
            let size = decodeURIComponent(storedViewSize);
            setViewSize(size);
        } else {
            let baseSize = "200";
            setViewSize(baseSize);
        }
        if (storedInputViewSize) {
            let inputSize = decodeURIComponent(storedInputViewSize);
            setInputViewSize(inputSize);
        } else {
            let baseSize = "180";
            setInputViewSize(baseSize);
        }
        if (storedPreviewShowState !== undefined) {
            setPreviewShow(decodeURIComponent(storedPreviewShowState) === 'true');
        } else {
            setPreviewShow(true);
        }
    }, []);

    const TABS = [
        {
            id: 'chatView',
            title: 'VIEW',
            content: <div style={{height: viewSize+"px", overflowY: "scroll"}} dangerouslySetInnerHTML={{ __html: viewChatText }} ref={repTextRef} />,
        },
        {
            id: 'ChatCode',
            title: 'CODE',
            content: <CustomTextbox height={viewSize + "px"} value={viewChatCode} />,
        },
    ];

    return (
        <TemplatePage>
            <CustomButton variant="primary" onClick={handleShow}>
                プレビュー
            </CustomButton>

            <Offcanvas show={show} onHide={handleClose}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>特殊タグのプレビュー画面</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div dangerouslySetInnerHTML={{ __html: viewChatText }} />
                </Offcanvas.Body>
            </Offcanvas>

            {!previewShow && <p style={{ color: "red" }}>※プレビュー画面の固定化：無効</p>}
            {previewShow && <p style={{ color: "green" }}>※プレビュー画面の固定化：有効</p>}
            {previewShow && <Tabs tabs={TABS} />}
            {previewShow && <br />}

            <CustomForm height='50px' label="Title" onChange={handleTitleChange} value={chatTitle} />
            <Form>
                <Form.Check // prettier-ignore
                    type="switch"
                    id="userNumberChack"
                    label="自分"
                    checked={userNumber === 1}
                    onChange={handleUserNumberChange}
                />
            </Form>
            <div className="row">
                <div className="col">
                    <CustomForm height="50px" label="Name" onChange={handleUserNameChange} value={userName} disabled={userNumber === 1} />
                </div>
                <div className="col">
                    <CustomSelect labelView={true} label="会話相手の履歴 ▼" options={userNameBox} onChange={handleSelectChange} disabled={userNumber === 1} />
                </div>
            </div>
            <CustomForm height={inputViewSize + "px"} onChange={handleUserTextChange} value={userText} />
            <Form>
                <Form.Check // prettier-ignore
                    type="switch"
                    id="userReadCheck"
                    label="既読"
                    checked={userRead === 1}
                    onChange={handleUserReadChange}
                    disabled={userNumber === 2}
                />
            </Form>
            <div className='row'>
                <div className='col'>
                    <CustomButton variant="warning" width='100%' onClick={onClick_Copy}>コピー</CustomButton>
                </div>
                <div className='col'>
                    <span />
                </div>
                <div className='col'>
                    <CustomButton variant="danger" width='100%' onClick={onClick_Reset}>X</CustomButton>
                </div>
            </div>
            <CustomButton width='100%' onClick={onClick}>投稿</CustomButton>
        </TemplatePage>
    );
}

export default Template;