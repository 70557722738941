import React, { forwardRef } from 'react';
import { Button } from 'react-bootstrap';
import './UnionIcon.css';

type RES_Content = {
    res_no: number; // レス番号
    res_name: string; // レス名
    res_id: string; // レスID
    res_text: string; // レス内容
    originalID?: string;
};

export interface ResProps {
    title: string,
    picCol: string,
    resArray: RES_Content[],
    editModal: (res: RES_Content[], originalId: string) => void;
    removeItem: (originalId: string) => void;
    size?: string,
    switchs?: boolean,
    height?: string,
}

export const ResUnion = forwardRef<HTMLDivElement, ResProps>(({title, picCol, resArray, editModal, removeItem, size, switchs, height}, ref) => {
    const resSize = size ? size : "18px";
    const resSwitchs = switchs !== undefined ? switchs : true; // デフォルト値を true にする
    const resHeight = height ? height : "200px";

    // 改行を <br /> に変換して表示する関数
    const formatTextWithLineBreaks = (text: string) => {
        return text.split('\n').map((line, index) => (
            <React.Fragment key={index}>
                <span>{line}</span>
                <br />
            </React.Fragment>
        ));
    };

    return (
        <>
            <div ref={ref} style={{ ...(resHeight !== "0px" && { height: resHeight }), overflowY: 'scroll', whiteSpace: 'pre-wrap', backgroundColor: picCol, fontSize: resSize, padding: "10px" }}>
                <h2>{title}</h2>

                {resArray.map((res, index) => (
                    <div key={index} >
                        {resSwitchs ? (
                            <>
                                <span style={{ padding: "0px 5px 0px 0px" }}>
                                    {res.res_no}: <span style={{ color: "#008000" }}>{res.res_name}</span> ID:{res.res_id}
                                </span>
                                <Button
                                    size='sm'
                                    className='mb-1'
                                    onClick={() => res.originalID ? editModal(resArray, res.originalID) : undefined}
                                    variant="outline-primary"><i className="bi bi-pencil-fill"></i></Button>
                                <span style={{ padding: "0px 5px 0px 0px" }} />
                                <Button
                                    size='sm'
                                    className='mb-1'
                                    onClick={() => res.originalID ? removeItem(res.originalID) : undefined}
                                    variant="outline-danger"><i className="bi bi-backspace-fill"></i></Button>

                                {/* 改行を反映するために formatTextWithLineBreaks 関数を使用 */}
                                <p>{formatTextWithLineBreaks(res.res_text)}</p>
                            </>
                        ) : (
                            <>
                                <span style={{ padding: "0px 5px 0px 0px" }}>{res.res_no}: <span style={{ color: "#008000" }}>{res.res_name}</span></span>
                                <Button
                                    size='sm'
                                    className='mb-1'
                                    onClick={() => res.originalID ? editModal(resArray, res.originalID) : undefined}
                                    variant="outline-primary"><i className="bi bi-pencil-fill"></i></Button>
                                <span style={{ padding: "0px 5px 0px 0px" }} />
                                <Button
                                    size='sm'
                                    className='mb-1'
                                    onClick={() => res.originalID ? removeItem(res.originalID) : undefined}
                                    variant="outline-danger"><i className="bi bi-backspace-fill"></i></Button>

                                <p>{formatTextWithLineBreaks(res.res_text)}</p>
                            </>
                        )}
                    </div>
                ))}
            </div>
        </>
    );
});

ResUnion.displayName = 'ResUnion';