import React from 'react';
import TemplatePage from "./App";
import { Alert, Card, ListGroup, Badge, CardBody } from 'react-bootstrap';
import { Link } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';

const Schedule = () => {
    return (
        <TemplatePage>
            <Alert variant="success">
                <Alert.Heading>はじめてご利用される方へ</Alert.Heading>
                <span>当サイトはいかなる小説投稿サイト様とも直接的な関係を持たない、有志による非公式・非公認サイトです。</span>
                <p>ご利用前は必ず<Link to='./schedule/services'>利用規約</Link>をお読み頂き、ご承諾の上でご利用ください。</p>

                <p>また、サイトドメインを「txh-g.tank.jp」から「txh-g.com」に変更します。旧ドメインからアクセスされている方は、2025年1月1日以降は閲覧いただけませんのでご注意ください。</p>
            </Alert>
            <h2>実装済み報告</h2><small>※過去2週間分</small>
            <Card>
                <Card.Body>
                    <dl>
                        <dt><h4>10/28 実装/お知らせ <Badge bg="danger">New</Badge></h4></dt>
                        <dd><b style={{ color: "#dc3545" }}>NEWS</b> - 微調整と機能アップデートを完了しました</dd>
                        <dd><b style={{ color: "#0d6efd" }}>Edit/RES</b> - 「MODIFY」タブを廃止。「VIEW」タブの名称を「EDIT」に変更し、EDIT内にレス編集/削除ボタンを追加。※このボタンはCODEコピー時にはデザインに反映されません</dd>
                        <dd><b style={{ color: "green"}}>ALL</b> - <u>今後のメンテナンスについて。基本隔週で日曜日夜間に定期実施します。次回のメンテナンスは11月10日～11日の間に実施されます。</u></dd>
                        <hr />
                        <dt><h4>10/24 実装/お知らせ</h4></dt>
                        <dd><b style={{ color: "#dc3545" }}>NEWS</b> - 微調整と機能アップデートを完了しました</dd>
                        <dd><s><b style={{ color: "#0d6efd" }}>Edit</b> - 「RES」にて「MODIFY」タブを追加。投稿したレスを編集・削除可能になった</s></dd>
                        <dd><b style={{ color: "#0d6efd" }}>Simple/Edit/Setting</b> - ボタン位置やサイズ等の調整</dd>
                        <hr />
                        <dt><h4>10/23 実装/お知らせ</h4></dt>
                        <dd><b style={{ color: "#dc3545" }}>NEWS</b> - 微調整を完了しました</dd>
                        <dd><b style={{ color: "#0d6efd" }}>Edit</b> - 「RES」にてテキストコピー機能を追加、表示文字サイズ調整機能</dd>
                        <dd><b style={{ color: "#0d6efd" }}>Simple/Edit/Setting</b> - ボタン位置やサイズ等の調整、押下後のアラート機能等を修正</dd>
                        <dd><b style={{ color: "#0d6efd" }}>Service</b> - 「利用規約」ページを追加</dd>
                        <hr />
                    </dl>

                    <br />
                    <p>すべての履歴は<Link to='./schedule/blog'>BLOG</Link>をご覧ください。</p>
                </Card.Body>
            </Card>

            <br />

            <h2>実装予定</h2>
            <ListGroup>
                <ListGroup.Item><s>Edit - レスごとの削除機能</s> <b style={{ color: "#dc3545"}}>完了</b></ListGroup.Item>
                <ListGroup.Item>Edit - 投稿日時を表示する(ID同様On/Offあり) <b style={{ color: "green"}}>11/10予定</b></ListGroup.Item>
                <ListGroup.Item>Edit - スレッド保存機能(localStorageで最大3つ、.txtとしてローカル出力/読込可能にする)　<b style={{ color: "green"}}>11/10予定</b></ListGroup.Item>
                <ListGroup.Item>Simple - 配信コメント一覧風画面の実装</ListGroup.Item>
                <ListGroup.Item></ListGroup.Item>
                <ListGroup.Item>その他検討中</ListGroup.Item>
                <ListGroup.Item></ListGroup.Item>
            </ListGroup>
        </TemplatePage>
    );
}

export default Schedule;