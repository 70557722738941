import React, { useState, useEffect, useRef, useDebugValue } from 'react';
import TemplatePage from "./App";
import Tabs from "./Custom/Tabs";
import BaseModals from './Custom/BaseModals';
import { ResUnion, ResProps } from './Custom/BBS/UnionMode';
import { Alert, Button, Form, Offcanvas, OverlayTrigger , Tooltip, TooltipProps, ListGroup } from 'react-bootstrap';
import CustomTextbox from "./Custom/CustomTextbox";
import CustomForm from "./Custom/BBS/CustomForm";
import CustomTitle from './Custom/BBS/CustomTitle';
import CustomRange from './Custom/BBS/CustomRange';
import CustomSelect from './Custom/BBS/CustomSelect';
import CustomPic from './Custom/BBS/CustomPic';
import Cookies from 'js-cookie';
import CustomButton from './Custom/CustomButton';
import { generateRandomString, RES_Union, RES_Union_H } from './Custom/BBS/Option'
import CustomResNum from './Custom/BBS/CustomResNum';
import { JSX } from 'react/jsx-runtime';

type RES_Content = {
    res_no: number; // レス番号
    res_name: string; // レス名
    res_id: string; // レスID
    res_text: string; // レス内容
    originalID?: string; // 操作用の固定ID
};

interface Option {
    value: string;
    label: string;
}

const getOptionsNameCookie = (): Option[] => {
    const nameListCookie = Cookies.get('nameList');
    if (nameListCookie) {
        const nameList = JSON.parse(decodeURIComponent(nameListCookie)) as string[];
        return nameList.map((name, index) => ({
            value: name,
            label: name
        }));
    }
    return [];
};

const getOptionsIdCookie = (): Option[] => {
    const idListCookie = Cookies.get('idList');
    if (idListCookie) {
        const idList = JSON.parse(decodeURIComponent(idListCookie)) as string[];
        return idList.map((id, index) => ({
            value: id,
            label: id
        }));
    }
    return [];
};

// Cookieに保存するためのユーティリティ関数
const setCookie = (name: string, value: string, days: number) => {
    Cookies.set(name, value, { expires: days });
}

// localStorageに保存するための関数
const saveResArrayToLocalStorage = (resArray: RES_Content[]) => {
    localStorage.setItem('resArray', JSON.stringify(resArray));
};

// localStorageから取得するための関数
const getResArrayFromLocalStorage = (): RES_Content[] => {
    const resArrayString = localStorage.getItem('resArray');
    if (resArrayString) {
        return JSON.parse(resArrayString) as RES_Content[];
    }
    return [];
};

const TestEdit = () => {
    const inputRef = useRef<HTMLTextAreaElement>(null);

    const [options, setOptions] = useState<Option[]>([]);
    const [options_id, setOptions_id] = useState<Option[]>([]);
    const [seleted_id, setSelected_id] = useState<Option[]>([]);

    const [title, setTitle] = useState(``);
    const [text, setText] = useState('');
    const [repText, setRepText] = useState('');
    const [h_repText, h_setRepText] = useState('');
    const [F_size, setFsize] = useState(16);
    const [selectedOption, setSelectedOption] = useState('ななしのネット民');
    const [pickerCol, setPickerCol] = useState('#e0e0e0');
    const [chatId, setChatId] = useState(generateRandomString(10));
    const [switchState, setIsSwitchOn] = useState(true);
    const [resNo, setResNo] = useState(1);
    const repTextRef = useRef<HTMLDivElement>(null);
    const hRepTextRef = useRef<HTMLDivElement>(null);

    // レス内容操作・保存用
    const [resList, setResList] = useState<RES_Content[]>([]);
    // レス内容編集用modal
    const [modalShow, setModalShow] = useState(false);
    // modal用に整えたResList
    const [modalResNo, setModalResNo] = useState(0);
    const [modalResName, setModalResName] = useState("");
    const [modalResId, setModalResId] = useState("");
    const [modalResText, setModalResText] = useState("");
    const [originalID, setOriginalId] = useState("");
    
    // サイズ等の設定用フック
    const [viewSize, setViewSize] = useState('200');
    const [inputViewSize, setInputViewSize] = useState('180');
    const [previewShow, setPreviewShow] = useState(true);
    const [codeCopyOnOff, setCodeCopyOnOff] = useState(false);
    const [textCopy, setTextCopy] = useState("");
    
    // offcanbas用のフック
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // レス内容入力した際のhandle
    const handleTextChange = (value: string) => {
        setText(value);
    };

    //　スレタイトル入力した際のhandle
    const handleTitleChange = (value: string) => {
        setTitle(value);
        const newResArray = newResArrayAdd(1);
        submitUnion(value, newResArray, switchState);
    };

    // レス用リストの内容が更新された時
    // IDを削除する関数
    const handleRemoveId = (originalId: string) => {
        const userConfirmed = window.confirm('レスを削除すると復元できません。\nCookieも削除されますがよろしいですか？');
        if (userConfirmed) {
            const newResArray = resList.filter(res => res.originalID !== originalId);

        // ステートを更新
        submitUnion(title, newResArray, switchState);
        setResList(newResArray);

        // localStorageに保存
        saveResArrayToLocalStorage(newResArray);
        }else{
            alert("処理をキャンセルしました。");
        }
    };

    // モーダルを開く際にデータをセットする関数
    const handleOpenModal = (date: RES_Content[], originalId: string) => {
        setOriginalId(originalId);
        const modalValue = date.find(res => res.originalID === originalId);

        setModalResNo(modalValue?.res_no ?? 0);
        setModalResName(modalValue?.res_name ?? "");
        setModalResId(modalValue?.res_id ?? "");
        setModalResText(modalValue?.res_text ?? "");

        setModalShow(true);            // モーダルを開く
    };

    //test用
    const Modal_handlResNo = (value:string) => {
        let newValu = value;
        setModalResNo(Number(newValu));
    }

    const Modal_handlResName = (value:string) => {
        let newValu = value;
        setModalResName(newValu);
    }

    const Modal_handlResId = (value:string) => {
        let newValu = value;
        setModalResId(newValu);
    }

    const Modal_handlResText = (value:string) => {
        let newValu = value;
        setModalResText(newValu);
    }

    const handlCloseModal = () => {
        console.log("ID:"+originalID)
        let newDate = resList.map(res =>
            res.originalID === originalID
                ? { ...res,
                    res_no:     modalResNo  , // originalIDが一致する場合、res_noを更新
                    res_name:   modalResName,   // res_nameを更新
                    res_id:     modalResId  , // res_idを更新
                    res_text:   modalResText,}
                : res // 一致しない場合、そのまま返す
        );

        // localStorageに保存
        saveResArrayToLocalStorage(newDate);
    
        // ステートが更新された後にsubmitUnionを呼び出す
        submitUnion(title, newDate, switchState);
        setOriginalId("");
        setModalResNo(0);
        setModalResName("");
        setModalResId("");
        setModalResText("");
        setModalShow(false)
    }

    const handleRangeChange = (value: number) => {
        setFsize(value);
        const newResArray = newResArrayAdd(1);
        submitUnion(title, newResArray, switchState);
    };

    const handlePicChange = (value: string) => {
        setPickerCol(value);
    }

    const handleSelectChange = (value: string) => {
        console.log("セレクト：",value)
        setSelectedOption(value);
    };

    const handleIdsChange = (value: string) => {
        setChatId(value);
    };

    const handleResNoChange = (value: string) => {
        setResNo(parseInt(value))
    }

    // テキストのみを保存する際、IDありなしの切り替え時に反映されないためもう少し精査する必要がある
    const textCopyUnion = (base: RES_Content[], idChecks: boolean) => {
        let copyItem = ``;

        if(idChecks){
            
            for(let t of base){
                copyItem += `${t.res_no}：${t.res_name} ID:${t.res_id}
${t.res_text}
 
`
            }
        }else{
            for(let t of base){
                copyItem += `${t.res_no}：${t.res_name}
${t.res_text}
 
`
            }
        }

        return copyItem;
    }

    // 投稿に関する処理一覧
    const submitBase = () => {
        const newResArray = newResArrayAdd();
        submitUnion(title, newResArray, switchState);

        // テキスト用のコピーを取る
        let copyItem = textCopyUnion(newResArray, switchState);

        // localStorageに保存
        saveResArrayToLocalStorage(newResArray);

        let resNewNo = resNo + 1;

        setCookie('resTitle', encodeURIComponent(title), 30);
        setCookie('resNewNo', encodeURIComponent(resNewNo.toString()), 30);

        setCookie('textCopyItem', encodeURIComponent(copyItem), 30);
        setCookie('codeCopyState', encodeURIComponent(codeCopyOnOff.toString()), 30);

        setResNo(resNo + 1);
        let random_Id = generateRandomString(10);
        setChatId(random_Id);
        setSelected_id([{ value: random_Id, label: random_Id }, ...options_id])
        setText("");
    }

    const submitUnion = (titles: string, resArray: RES_Content[], idCheck: boolean) => {
        setResList(resArray);
        let fontSize = F_size + "px";
        let allText = RES_Union(titles, pickerCol, resArray, fontSize, idCheck);
        let allCode = RES_Union_H(titles, pickerCol, resArray, idCheck);
        // テキストコピー用の処理
        let textCopy = textCopyUnion(resArray, idCheck);
        setRepText(allText);
        h_setRepText(allCode);
        setTextCopy(textCopy);
    }

    // 新規のレスを追加
    const newResArrayAdd = (check: number = 0) => {
        // localStorageから既存のresArrayを取得
        const existingResArray = getResArrayFromLocalStorage();

        // レス操作時にキーとなるオリジナルIDを発行
        let original = generateRandomString(12);

        // 新しいレスを既存のresArrayに追加
        let newResArray = existingResArray;
        if (check === 0) {
            newResArray = [...existingResArray, { res_no: resNo, res_name: selectedOption, res_id: chatId, res_text: text, originalID: original }];
        }

        return newResArray;
    }

    // 投稿処理
    const onClick = () => {
        submitBase();
    };

    const copyToClipboard = (text: string) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                alert("コピーしました。");
            })
            .catch(err => {
                alert("Failed to copy text to clipboard: 詳細はコンソールログを確認してください。");
                console.error('Failed to copy text to clipboard:', err);
                // エラーハンドリングを行う場合はここで適切に処理を追加します
            });
    };

    // CODE or TEXT コピー
    const onClick_Copy = () => {
        if(codeCopyOnOff){
            copyToClipboard(textCopy);
        }else{
            copyToClipboard(h_repText);
        }
    }

    const handleCodeCopyOnOffSwitchChange = () => {
        setCodeCopyOnOff(!codeCopyOnOff);
    };

    const onClick_Reset = () => {
        const userConfirmed = window.confirm('リセットすると復元できません。\nCookieも削除されますがよろしいですか？');
        if (userConfirmed) {
            // ユーザーがOKをクリックした場合の処理
            let titles = title;

            setResNo(1);
            let random_Id = generateRandomString(10);
            setChatId(random_Id);
            setSelected_id([{ value: random_Id, label: random_Id }, ...options_id])
            setText("");

            setRepText(``);
            h_setRepText(``);
            setTextCopy(``);
            setResList([]);

            // localStorageのリセット
            localStorage.removeItem('resArray');

            console.log(`タイトル：${titles} はリセットされました。`);
            // ここにリセット処理を記述します
        } else {
            // ユーザーがキャンセルをクリックした場合の処理
            alert(`リセットがキャンセルされました。`);
        }
    };

    /** ここから先はキーイベントに関する処理 */

    const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        // 例: Ctrl+Sが押されたときに何かをする
        if (event.ctrlKey && event.key === 's') {
            event.preventDefault();
            submitBase();
        }
    };
    /** ここまでがキーイベントに関する処理 */


    useEffect(() => {
        if (repTextRef.current) {
            repTextRef.current.scrollTop = repTextRef.current.scrollHeight;
        }
    }, [repText]);

    useEffect(() => {
        if (hRepTextRef.current) {
            hRepTextRef.current.scrollTop = hRepTextRef.current.scrollHeight;
        }
    }, [h_repText]);

    useEffect(() => {
        
    },[F_size])

    // localStorageに保存した情報を取得し表示する処理
    useEffect(() => {
        // ID 表示するかしないかのswitch
        let idChecks = true;
        const savedSwitchState = Cookies.get('switchState');
        if (savedSwitchState !== undefined) {
            idChecks = (decodeURIComponent(savedSwitchState) === `true`);
            setIsSwitchOn(idChecks);
        }

        /// const savedCodeCopyState = Cookies.get('codeCopyState');
        /// if (savedCodeCopyState !== undefined) {
        ///     setIsSwitchOn(decodeURIComponent(savedCodeCopyState) === 'true');
        /// }

        const savedTextCopyItem = Cookies.get('textCopyItem');
        if(savedTextCopyItem !== undefined){
            setTextCopy(decodeURIComponent(savedTextCopyItem));
        }

        const storedResTitle = Cookies.get('resTitle');
        const storedResNewNo = Cookies.get('resNewNo');

        const storedResArray = getResArrayFromLocalStorage();
        // 保存した設定情報に基づいて、ストレージ内にテキストがあれば整形して表示する処理
        submitUnion(title, storedResArray, idChecks);

        const optionsNameCookie = getOptionsNameCookie();
        setOptions(optionsNameCookie);
        setSelectedOption(optionsNameCookie[0].value);

        const optionsIdCookie = getOptionsIdCookie();
        setOptions_id(optionsIdCookie);
        setSelected_id([{ value: chatId, label: chatId }, ...optionsIdCookie]);

        if (storedResTitle) {
            let titles = decodeURIComponent(storedResTitle);
            setTitle(titles);
        }
        if (storedResNewNo) {
            let newNo = decodeURIComponent(storedResNewNo);
            setResNo(Number(newNo));
        }

        const storedViewSize = Cookies.get('viewSize');
        const storedInputViewSize = Cookies.get('InputViewSize');
        const storedPreviewShowState = Cookies.get('previewShowState');

        if (storedViewSize) {
            let size = decodeURIComponent(storedViewSize);
            setViewSize(size);
        } else {
            let baseSize = "200";
            setViewSize(baseSize);
        }
        if (storedInputViewSize) {
            let inputSize = decodeURIComponent(storedInputViewSize);
            setInputViewSize(inputSize);
        } else {
            let baseSize = "180";
            setInputViewSize(baseSize);
        }
        if (storedPreviewShowState !== undefined) {
            setPreviewShow(decodeURIComponent(storedPreviewShowState) === 'true');
        } else {
            setPreviewShow(true);
        }
    }, []);

    useEffect(() => {
        inputRef.current?.focus(); // フォーカスを設定
    }, []);

    const TABS = [
        {
            id: "edit-text",
            title: "EDIT",
            content: <ResUnion title={title} picCol={pickerCol} resArray={resList} editModal={handleOpenModal} removeItem={handleRemoveId} size={F_size.toString() + "px"} switchs={switchState} height={viewSize + "px"} ref={repTextRef} />
        },
        {
            id: 'h_text',
            title: 'CODE',
            content: <CustomTextbox height={viewSize + "px"} size={F_size + "px"} value={h_repText} />,
        },
    ];

    const ModifyModal = () => {
        return (
            <div>
                <div className='row'>
                    <p style={{fontSize: "12px"}}>※ 2024/10/27 以前のバージョンで作成されたレスには使用できません。</p>
                </div>
                <div className='row'>
                    <div className='col-2'>
                        <CustomResNum onChange={Modal_handlResNo} values={modalResNo.toString()} />
                    </div>
                    <div className='col-5'>
                        <CustomTitle height='30px' onChange={Modal_handlResName} value={modalResName} label='ここに名前を入力' />
                    </div>
                    <div className='col-5'>
                        <CustomTitle height='30px' onChange={Modal_handlResId} value={modalResId} label='ここにIDを入力' />
                    </div>
                </div>
                <div className='row'>
                    <CustomForm ref={inputRef} height={"100px"} onChange={Modal_handlResText} label='res text' value={modalResText} />
                </div>
            </div>
        )
    }

    return (
        <TemplatePage>
            
            <BaseModals
                show={modalShow}
                onHide={() => setModalShow(false)}
                title="レスの編集"
                footerContent={<Button onClick={() => handlCloseModal()}>完了</Button>}
            >
                {/* 任意のコンテンツをchildrenとして渡す */}
                {ModifyModal()}
            </BaseModals>


            <CustomButton size="sm" variant="primary" onClick={handleShow}>
            <i className="bi bi-box-arrow-in-up-left"></i> プレビュー
            </CustomButton>

            <Offcanvas show={show} onHide={handleClose}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>特殊タグのプレビュー画面</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                <ResUnion title={title} picCol={pickerCol} resArray={resList} editModal={handleOpenModal} removeItem={handleRemoveId} size={F_size.toString() + "px"} switchs={switchState} height={"0px"} ></ResUnion>
                </Offcanvas.Body>
            </Offcanvas>

            {!previewShow && <p style={{ color: "red", fontSize: "14px" }}>※プレビュー画面の固定化：無効<span style={{color:"black", fontSize: "14px"}}> / フォント： {F_size} px</span></p>}
            {previewShow && <p style={{ color: "green", fontSize: "14px" }}>※プレビュー画面の固定化：有効<span style={{color:"black", fontSize: "14px"}}> / フォント： {F_size} px</span></p>}
            {previewShow && <Tabs tabs={TABS} />}
            {previewShow && <br />}
            <div className='d-flex align-items-center'>
            <Form>
                <Form.Check // prettier-ignore
                    type="switch"
                    id="custom-switch-text"
                    label={<span style={{fontSize: "12px"}}>CODEではなくテキストをコピーする</span>}
                    style={{fontSize: "12px"}}
                    checked={codeCopyOnOff}
                    onChange={handleCodeCopyOnOffSwitchChange}
                />
            </Form>
            </div>
            <p />

            <CustomTitle height='30px' onChange={handleTitleChange} value={title} label='ここにタイトルを入力' />
            <p />
            <div className="row">
                <div className="col">
                    <CustomResNum onChange={handleResNoChange} values={resNo.toString()} />
                </div>
                <div className="col">
                    <CustomSelect options={options} onChange={handleSelectChange} />
                </div>
                <div className="col">
                    <CustomSelect options={seleted_id} selectedValue={chatId} onChange={handleIdsChange} />
                </div>
                <div className='col'>
                    <CustomPic onChange={handlePicChange} />
                </div>
            </div>
            <CustomRange size={F_size} onRangeChange={handleRangeChange} />
            <CustomForm height={inputViewSize + "px"} onChange={handleTextChange} onKeyDown={handleKeyDown} value={text} />
            <span style={{fontSize: "12px"}}>※ PC版では「Ctrl + S」で投稿処理可能です。</span>
            <div className='row'>
                <div className='col'>
                    <CustomButton variant="warning" width='100%' onClick={onClick_Copy}>コピー</CustomButton>
                </div>
                <div className='col'>
                <CustomButton width='100%' onClick={onClick}>投稿</CustomButton>
                </div>
                <div className='col'>
                    <CustomButton variant="danger" width='100%' onClick={onClick_Reset}>リセット</CustomButton>
                </div>
            </div>
        </TemplatePage>
    );
};

export default TestEdit;