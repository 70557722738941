import React from 'react';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';

interface CustomTitleProps {
    onChange: (value: string) => void;
    onKeyDown?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
    value: string;
    height?: string;
    label?: string;
    disabled?: boolean;
}

const CustomTitle: React.FC<CustomTitleProps> = ({ onChange, onKeyDown, value, height = "150px", label="Comments",disabled}) => {
    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        onChange(e.target.value);
    };

    return (
        <>
                <Form.Control
                    as="textarea"
                    placeholder={label}
                    style={{ height: height }}
                    onChange={handleChange}
                    onKeyDown={onKeyDown} // Add the onKeyDown event here
                    value={value}
                    disabled={disabled}
                />
        </>
    );
};

export default CustomTitle;