import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

interface BaseModalsProps {
    show: boolean;
    onHide: () => void;
    title: string;
    children: React.ReactNode;  // 任意の内容を受け取るためのプロパティ
    footerContent: React.ReactNode;
}

const BaseModals: React.FC<BaseModalsProps> = ({ show, onHide, title, children, footerContent }) => {
    return (
        <Modal
            show={show}
            onHide={onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {children}  {/* 外部から渡されたコンテンツを表示 */}
            </Modal.Body>
            <Modal.Footer>{footerContent}</Modal.Footer>
        </Modal>
    );
};

export default BaseModals;
