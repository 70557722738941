import React from "react";
import { Button, ButtonProps as BootstrapButtonProps } from "react-bootstrap";

interface ButtonProps extends BootstrapButtonProps {
  className?: string;
  width?: string;
  height?: string;
  fontsize?: string;
}

const CustomButton = (props: ButtonProps) => {
  const { className = "mb-3", width, height, fontsize, children, ...rest } = props;

  return (
    <Button className={className} style={{ width: width, height: height, fontSize: fontsize}} {...rest}>
      {children}
    </Button>
  );
};

export default CustomButton;