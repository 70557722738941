import React, { useState, useEffect } from 'react';
import TemplatePage from './App';
import CustomButton from './Custom/CustomButton';
import Cookies from 'js-cookie';
import { Form, ListGroup, Toast, ToastContainer } from 'react-bootstrap'; // React BootstrapからListGroupをインポート

// Cookieに保存するためのユーティリティ関数
const setCookie = (name: string, value: string, days: number) => {
    Cookies.set(name, value, { expires: days });
}

// 初期値を設定する関数
const initializeCookie = (name: string, defaultValue: any, days: number) => {
    if (!Cookies.get(name)) {
        setCookie(name, encodeURIComponent(JSON.stringify(defaultValue)), days);
    }
}

// 全体の設定を行う
const Setting = () => {

    // IDの設定に関するstate
    const [idList, setIdList] = useState<string[]>([]);
    // Nameの設定に関するstate
    const [nameList, setNameList] = useState<string[]>([]);

    // IDを表示するかどうか
    const [isSwitchOn, setIsSwitchOn] = useState(true);

    // 画面サイズのフック
    const [viewSize, setViewSize] = useState("200");
    const [inputViewSize, setInputViewSize] = useState("100");

    // プレビュー画面固定表示に対するフック
    const [previewShow, setPreviewShow] = useState(true);

    // Toast表示に関するフック
    const [showA, setShowA] = useState(true);
    const toggleShowA = () => setShowA(!showA);

    // Toast用のフック
    const [toast_show, setToast_Show] = useState(false);

    // コンポーネントがマウントされた時にCookieからIDとNameリストを読み込む
    useEffect(() => {
        // 初期値設定
        initializeCookie('idList', ["test_Id"], 30);
        initializeCookie('nameList', ["ななしのネット民"], 30);
        initializeCookie('switchState', true, 30);
        initializeCookie('viewSize', "200", 30);
        initializeCookie('InputViewSize', "180", 30);
        initializeCookie('previewShowState', true, 30);

        const storedIdList = Cookies.get('idList');
        const storedNameList = Cookies.get('nameList');
        if (storedIdList) {
            setIdList(JSON.parse(decodeURIComponent(storedIdList)));
        }
        if (storedNameList) {
            setNameList(JSON.parse(decodeURIComponent(storedNameList)));
        }

        const savedSwitchState = Cookies.get('switchState');
        if (savedSwitchState !== undefined) {
            setIsSwitchOn(decodeURIComponent(savedSwitchState) === 'true');
        }

        const storedViewSize = Cookies.get('viewSize');
        if (storedViewSize) {
            setViewSize(JSON.parse(decodeURIComponent(storedViewSize)));
        }

        const storedInputViewSize = Cookies.get('InputViewSize');
        if (storedInputViewSize) {
            setInputViewSize(JSON.parse(decodeURIComponent(storedInputViewSize)));
        }

        const savepreviewShowState = Cookies.get('previewShowState');
        if (savepreviewShowState !== undefined) {
            setPreviewShow(decodeURIComponent(savepreviewShowState) === 'true');
        }
    }, []); // []を渡すことで初回のみ実行される

    // 入力されたIDを追加する関数
    const handleAddId = () => {
        const idInput = document.getElementById('idInput') as HTMLInputElement;
        const newId = idInput.value.trim();

        // 日本語（ひらがな、カタカナ、漢字）を含むかチェックする正規表現
        const japaneseRegex = /[\u3040-\u309F\u30A0-\u30FF\u4E00-\u9FAF]/;

        if (newId && !japaneseRegex.test(newId)) {
            setIdList([...idList, newId]);
            idInput.value = ''; // 入力欄をクリアする
        } else {
            alert("入力できません。英数字と一部の記号のみが許可されています。");
        }
    };


    // 入力されたNameを追加する関数
    const handleAddName = () => {
        const nameInput = document.getElementById('nameInput') as HTMLInputElement;
        const newName = nameInput.value.trim();
        if (newName) {
            setNameList([...nameList, newName]);
            nameInput.value = ''; // 入力欄をクリアする
        }
    };

    // IDを削除する関数
    const handleRemoveId = (index: number) => {
        const updatedIdList = [...idList];
        updatedIdList.splice(index, 1); // 指定されたindexの要素を削除
        setIdList(updatedIdList);
    };

    // Nameを削除する関数
    const handleRemoveName = (index: number) => {
        const updatedNameList = [...nameList];
        updatedNameList.splice(index, 1); // 指定されたindexの要素を削除
        setNameList(updatedNameList);
    };

    // Cookieに保存する関数
    const handleSave = () => {
        // IDリストをCookieに保存
        setCookie('idList', encodeURIComponent(JSON.stringify(idList)), 30);
        // NameリストをCookieに保存
        setCookie('nameList', encodeURIComponent(JSON.stringify(nameList)), 30);
        setCookie('switchState', encodeURIComponent(isSwitchOn.toString()), 30);

        setCookie(`viewSize`, encodeURIComponent(viewSize), 30);
        setCookie(`InputViewSize`, encodeURIComponent(inputViewSize), 30);
        setCookie(`previewShowState`, encodeURIComponent(previewShow.toString()), 30);
        
        setToast_Show(true);
    };

    const handleSwitchChange = () => {
        setIsSwitchOn(!isSwitchOn);
    };

    // 数値入力に制限するためのhandle
    const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;
        if (/^\d*$/.test(newValue)) {
            setViewSize(newValue);
        }
    };

    // 数値入力に制限するためのhandle
    const handleInputSizeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;
        if (/^\d*$/.test(newValue)) {
            setInputViewSize(newValue);
        }
    };

    const handleViewShowSwitchChange = () => {
        setPreviewShow(!previewShow);
    };

    return (
        <>
            <TemplatePage>

                <ToastContainer position="top-center" className="p-3" style={{ zIndex: 1 }}>
                    <Toast show={showA} onClose={toggleShowA} > {/**delay={3000} autohide */}
                        <Toast.Header style={{ backgroundColor: '#dc3545', color: 'white' }}>
                            <strong className="me-auto">設定保存に関する注意</strong>
                            {/*<small>11 mins ago</small>*/}
                        </Toast.Header>
                        <Toast.Body>保存ボタンをクリックまたはタップするまで内容は保存されません。編集後は必ず保存ボタンを押してください。</Toast.Body>
                    </Toast>
                </ToastContainer>

                <div className="container">
                    <Toast bg={"success"} onClose={() => setToast_Show(false)} show={toast_show} delay={3000} autohide>
                        <Toast.Header>
                            <strong className="me-auto">機能設定</strong>
                            <small>success!</small>
                        </Toast.Header>
                        <Toast.Body>保存を完了しました。</Toast.Body>
                    </Toast>
                    <CustomButton variant='success' style={{ width: "100%" }} onClick={handleSave} className="mt-3">保存</CustomButton>
                    <p></p>

                    <h2>View size/show</h2>
                    <p>入力フォームのサイズや、プレビュー画面のサイズ/固定化の設定</p>
                    <hr />
                    {/** プレビュー画面を表示するか/しないか */}
                    <div className='mb-3'>
                        <Form>
                            <Form.Check // prettier-ignore
                                type="switch"
                                id="custom-switch"
                                label="プレビュー画面を固定表示する"
                                checked={previewShow}
                                onChange={handleViewShowSwitchChange}
                            />
                        </Form>
                        <br />
                        <label htmlFor="sizeInput" className="form-label">プレビュー画面のサイズを入力してください。※ピクセル(px)でご入力ください。</label>
                        <input
                            type="text"
                            id="sizeInput"
                            className="form-control"
                            inputMode="numeric"
                            maxLength={3}
                            style={{ width: "150px" }}
                            value={viewSize}
                            onChange={handleTextChange}
                        />
                        <br />
                        <label htmlFor="sizeInputBox" className="form-label">入力画面のサイズを入力してください。※ピクセル(px)でご入力ください。</label>
                        <input
                            type="text"
                            id="sizeInputBox"
                            className="form-control"
                            inputMode="numeric"
                            maxLength={3}
                            style={{ width: "150px" }}
                            value={inputViewSize}
                            onChange={handleInputSizeChange}
                        />
                    </div>

                    <hr />
                    <br />

                    <div>
                        <h2>ID List</h2>
                        <p>主に匿名掲示板で使用されるID Listの追加/削除</p>
                        <hr />
                        {/* IDのリスト表示 */}
                        <Form>
                            <Form.Check // prettier-ignore
                                type="switch"
                                id="custom-switch"
                                label="ID を表示する"
                                checked={isSwitchOn}
                                onChange={handleSwitchChange}
                            />
                        </Form>
                        <ListGroup>
                            {idList.map((id, index) => (
                                <ListGroup.Item key={index} className="d-flex justify-content-between align-items-center">
                                    {id}
                                    <CustomButton onClick={() => handleRemoveId(index)} variant="danger">X</CustomButton>
                                </ListGroup.Item>
                            ))}
                        </ListGroup>
                    </div>

                    {/* ID入力エリア */}
                    <div className="mb-3">
                        <label htmlFor="idInput" className="form-label">追加するID (max 10 characters)</label>
                        <input
                            type="text"
                            id="idInput"
                            className="form-control"
                            maxLength={10} // 最大文字数を10文字に制限
                            pattern="[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+" // 英数字と一部の記号のみ許可
                            title="English letters, digits, and some symbols only"
                            disabled={!isSwitchOn}
                        />
                        <CustomButton onClick={handleAddId} className="mt-2" disabled={!isSwitchOn}>追加</CustomButton>
                    </div>

                    <hr />
                    <p></p>
                    {/* Nameのリスト表示 */}
                    <div className="mt-4">
                        <h2>Name List</h2>
                        <p>主に匿名掲示板で使用される名前(コテハン)の追加/削除</p>
                        <hr />
                        <ListGroup>
                            {nameList.map((name, index) => (
                                <ListGroup.Item key={index} className="d-flex justify-content-between align-items-center">
                                    {name}
                                    <CustomButton onClick={() => handleRemoveName(index)} variant="danger">X</CustomButton>
                                </ListGroup.Item>
                            ))}
                        </ListGroup>
                    </div>

                    {/* Name入力エリア */}
                    <div className="mb-3">
                        <label htmlFor="nameInput" className="form-label">追加する名前</label>
                        <input
                            type="text"
                            id="nameInput"
                            className="form-control"
                            maxLength={50} // 例として最大50文字に制限
                        />
                        <CustomButton onClick={handleAddName} className="mt-2">追加</CustomButton>
                    </div>

                    <hr />
                    {/* 保存ボタン */}
                    <Toast bg={"success"} onClose={() => setToast_Show(false)} show={toast_show} delay={3000} autohide>
                        <Toast.Header>
                            <strong className="me-auto">機能設定</strong>
                            <small>success!</small>
                        </Toast.Header>
                        <Toast.Body>保存を完了しました。</Toast.Body>
                    </Toast>
                    <CustomButton variant='success' style={{ width: "100%" }} onClick={handleSave} className="mt-3">保存</CustomButton>
                    <p></p>
                </div>
            </TemplatePage>
        </>
    )
}

export default Setting;
