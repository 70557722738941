import React, { forwardRef } from 'react';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';

interface CustomFormProps {
    onChange: (value: string) => void;
    onKeyDown?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
    value: string;
    height?: string;
    label?: string;
    disabled?: boolean;
}

// forwardRefを使用してrefを渡せるようにする
const CustomForm = forwardRef<HTMLTextAreaElement, CustomFormProps>(
    ({ onChange, onKeyDown, value, height = "150px", label = "Comments", disabled }, ref) => {
        const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
            onChange(e.target.value);
        };

        return (
            <FloatingLabel controlId="floatingTextarea" label={label} className="mb-3">
                <Form.Control
                    as="textarea"
                    ref={ref}
                    placeholder="Leave a comment here"
                    style={{ height: height }}
                    onChange={handleChange}
                    onKeyDown={onKeyDown} // onKeyDownイベントの追加
                    value={value}
                    disabled={disabled}
                />
            </FloatingLabel>
        );
    }
);

export default CustomForm;